import React, { useState } from 'react';
//import Select from 'react-select';
import {Select,  Button, Input,Form, Field } from '@grafana/ui';

interface Option{
  value: string;
  label: string;
}

interface DropdownCheckboxFormProps {
  options: Option[]; // Update the type of the 'options' prop
  selectedOptions: Option[];
  setSelectedOptions: (selected: Option[]) => void;
  name: string;
  setName: (name: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  onSubmit: (
    selectedOptions: Option[],
    name: string,
    email: string
  ) => void;
  styles: any; // Replace 'any' with the actual type of 'styles'
}


const DropdownCheckboxForm: React.FC<DropdownCheckboxFormProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  name,
  setName,
  phone,
  setPhone,
  onSubmit,
  styles,
}) => {
  const handleOptionChange = (selected: any) => {
    setSelectedOptions(selected);
  };
 
  const [nameError, setNameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [selectedDevicesError,setSelectedDevicesError] = useState<string | null>(null);


  const handleSubmit = (e: React.FormEvent) => {
    if (!name.trim()) {
      setNameError('Název skupiny je povinný.');
    } else {
      setNameError(null);
    }
    
     
   

    if (!phone.trim()) {
      setPhoneError("Zadejte telefonní číslo.(+420)");
    }else if(!/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(phone)){
      setPhoneError("Špatný formát tel. čísla (+420 předvolba)");
    }else{
      setPhoneError(null);
    }
   
 


 
    if(selectedOptions.length  <= 0 ){
      setSelectedDevicesError("Zvolte zařízení");
    }else{
      setSelectedDevicesError(null);
    }
    // If both name and email are valid, proceed with submission
    if (nameError ===null && phoneError ===null && selectedDevicesError ===null) {
      onSubmit(selectedOptions, name, phone);
      setSelectedOptions([]);
      setName('');
      setPhone('');
    }

    //e.preventDefault();
   
    // Reset form fields
 
  };
  return (
    <div>
      
      <Form
      onSubmit={handleSubmit}
      >
       {({ register, errors }) => (
          <>
      <Field label="Název skupiny" invalid={!!nameError} error={nameError}>
        <Input
          type="text"
          value={name}
          onChange={(e) => {setName(e.currentTarget.value);setNameError(null);}}
          placeholder="Název skupiny"
        />
      </Field>
      <Field label="Telefonní číslo" invalid={!!phoneError} error={phoneError}>
        <Input
          type="tel"
          value={phone}
          onChange={(e) => {setPhone(e.currentTarget.value);setPhoneError(null);}}
          placeholder="Telefoní číslo"
        />
        </Field>
       
        <Field label="Zvolte zařízení:" invalid={!!selectedDevicesError} error={selectedDevicesError}>
        <Select
          options={options}
          isMulti
          onChange={(e)=>{handleOptionChange(e);setSelectedDevicesError(null);}}
          value={selectedOptions}
        />
      </Field>
        <Button  
        type="submit"
        size="md"
        variant='primary'
        fill="solid"
      >Přidat</Button>
           </>
        )}
      </Form>
      
     
    </div>
  );
};

export default DropdownCheckboxForm;
