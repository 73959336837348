import React, {  useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from '@emotion/css';
import { useStyles2, Button, useTheme2, CustomScrollbar, ConfirmModal, Tooltip, Input } from '@grafana/ui';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
//import { useTheme } from '@grafana/ui';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import DropdownCheckboxForm from './DropdownCheckboxForm';

interface Props extends PanelProps<SimpleOptions> {}




const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    ` ,
    deleteButton: css`
    display: inline-block;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  `,
 tableCell: css`
     display: table-cell;
     padding: 16px; 
     font-size: 0.875rem;
     text-align: left; 
     font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
     font-weight: 400; 
     line-height: 1.43; 
     letter-spacing: 0.01071em; 
     vertical-align: inherit; 
    `
  };
};
interface Option {
  value: string;
  label: string;
}


interface VematDevicesTable{
  id: number;
  name: string;
  coef: number | null;
  edited: boolean;
  showError: boolean;
}
interface Ierror {
  id: number;
  message: string;
  show: boolean;
}
type SmsSettings={
  deviceList: string[];
  groupName: string;
  phone: string;
  active: boolean;
  deviceNames: string[];
};

interface SmsTable{
id: number;
group_name: string;
settings: SmsSettings;
showModal: boolean;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  //console.log(data.request?.targets[0].datasource?.uid);
  const styles = useStyles2(getStyles);
  const theme = useTheme2();


  //The first value,  is our current state.The second value is the function that is used to update our state.

  const [devices,setDevices] = useState<VematDevicesTable[]>([]);
  const [SmsT,setSmsT] = useState<SmsTable[]>([]);
  const [_formError, setFormError] = useState<Ierror[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [update,setUpdate] = useState(false);

  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource:{
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const  fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

  //table select for vemat_devices 

  //const rawSql = `SELECT address,name,coef FROM vemat_devices order by id desc`;

  const headers = useMemo(() => {
    // Replace the following with your actual header initialization logic
    return {
      //Authorization: 'Bearer your_access_token',
      'Content-Type': 'application/json',
    };
  }, []);

  const handleFormSubmit = async (selectedOptions: Option[], name: string, phone: string) => {
    if (!name.trim()) {
      return;
      };
    if (!phone.trim()) {
      return;
    }else if(!/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(phone)){
      return;
    };
   
    if(selectedOptions.length  <= 0 ){
      return;
    };
    /*
    if (!name.trim()) {
      //alert('Název skupiny.');
      return;
    }

    if (!phone.trim()) {
      //alert('Zadejte telefonní číslo.(+420)');
      return;
    }
   
    if (!/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/.test(phone)) {
      //alert('Špatné telefonní číslo.');
      return;
    }
*/
    const selectedOptionValues = selectedOptions.map((option) => option.value);
    //const joined = selectedOptionValues.join(",");

    //console.log("Joined: ",joined);
    // Do something with the form data (e.g., submit to server)
    console.log('Selected Options:', selectedOptionValues);
    console.log('Name:', name);
    console.log('Phone:', phone);
    const toS: SmsSettings = {
     groupName:name,
     deviceList:selectedOptionValues,
     phone:phone,
     active:false,
     deviceNames:[]
    };

   const sqlInsert = `INSERT INTO sms_report_settings(group_name, settings) VALUES ('${name}', '${JSON.stringify(toS)}')`;
    console.log(sqlInsert);
   const updateQuery = createQuery('now-1h', 'now', datasourceUID, sqlInsert);
   await fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
    // Reset form fields
    setSelectedOptions([]);
    setName('');
    setPhone('');
   reloadTable();
  };


  const reloadTable = () =>{
    setUpdate(up=>!up);
  }
  useEffect(()=>{
    const createQuery = (
      from: string,
      to: string,
      datasourceUID: string | null = null,
      rawSql: string
    ) => {
      return {
        from: from,
        to: to,
        queries: [
          {
            refId: 'A',
            datasource: {
              uid: datasourceUID
            },
            //datasourceId: datasourceId,
            rawSql: rawSql,
            format: 'table',
          },
        ],
      };
    };
    
    const fetchData = (
      apiUrl: string,
      method: string,
      headers: Record<string, string>,
      body?: any
    ): Promise<any> => {
      return fetch(apiUrl, {
        method: method,
        headers: headers,
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          return null;
        });
    };

    const method = 'POST';
    const sqlCommands = [`SELECT address,name,coef FROM devices order by name asc`,
    `SELECT * FROM report_settings order by id asc`
  ];
    const select_vemat_devices = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);
    const headers =  {
      'Content-Type': 'application/json',
    };
    const reload = async()=>{

      fetchData(grafanaApiBaseUrl, method, headers, select_vemat_devices).then(data => {
        if (data) {
          const ids = data.results.A.frames[0].data.values[0];
          const names = data.results.A.frames[0].data.values[1];
          const coefs = data.results.A.frames[0].data.values[2];
    
          const mappedData = ids.map((id: number, index: number) => ({
            id,
            name: names[index],
            coef: coefs[index],
            edited: false,
            showError: false,
          }));
          //map error array
    
          setDevices(dev=>mappedData);
          //napamovani nactenych dat do StateVariable
        }
      });
    }
    reload();
  //1. fetch devices await
  //2. fetch filters and bind.

  },[update,datasourceUID,grafanaApiBaseUrl]);

  useEffect(()=>{
    const createQuery = (
      from: string,
      to: string,
      datasourceUID: string | null = null,
      rawSql: string
    ) => {
      return {
        from: from,
        to: to,
        queries: [
          {
            refId: 'A',
            datasource: {
              uid: datasourceUID
            },
            //datasourceId: datasourceId,
            rawSql: rawSql,
            format: 'table',
          },
        ],
      };
    };
    
    const fetchData = (
      apiUrl: string,
      method: string,
      headers: Record<string, string>,
      body?: any
    ): Promise<any> => {
      return fetch(apiUrl, {
        method: method,
        headers: headers,
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          return null;
        });
    };

    if(devices.length!==0){
console.log("Devices set");


const method = 'POST';

const headers =  {
  'Content-Type': 'application/json',
};
const sqlCommands = [`SELECT address,name,coef FROM devices order by address asc`,
`SELECT * FROM sms_report_settings order by id asc`
];
const select_vemat_sms = createQuery('now-1h', 'now', datasourceUID, sqlCommands[1]);

 fetchData(grafanaApiBaseUrl, method, headers,select_vemat_sms)
.then(data => {
  if (data) {

    const ids = data.results.A.frames[0].data.values[0];
    const names = data.results.A.frames[0].data.values[1];
    const settings = data.results.A.frames[0].data.values[2];
    console.log("Fetch2");
    const mappedData = ids.map((id: number, index: number) => ({

      id,
      group_name: names[index],
      settings: JSON.parse(settings[index]),
      showError: false,
    }));
    
    //map error array 

    const errList: Ierror[]=[];
    for(let i = 0 ; i < mappedData.length ; i++ ){
      errList.push({id:mappedData[i].id,message:"",show:false});
    }
    setFormError(errList);
    let _smsT: SmsTable[] = mappedData;
    let _devices = devices;
    _smsT.forEach(element => {
      let settings = element.settings;
      settings.deviceNames = [];
      for(let i=0;i<settings.deviceList.length;i++){
          let deviceId = parseInt(settings.deviceList[i],10);
          for(let j =0; j<_devices.length;j++){
            if(_devices[j].id===deviceId){
              settings.deviceNames.push(_devices[j].name);
              break;
            }
          }
      }
    });
    setSmsT(_smsT);

   
    //napamovani nactenych dat do StateVariable
  }
});
    };
  },[devices,datasourceUID,grafanaApiBaseUrl]);


/*
const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number, field: string) => {

  const newData = [...devices];
  newData[id].name = event.target.value;
  newData[id]["edited"]=true;
  const errs = [...formError];
  errs[id].show=false;
  errs[id].message="";
  setFormError(errs);
  setDevices(newData);
};
*/
  const handleDelete = (index: number) => {
   //setModal(false);
    const deleteSql = `DELETE FROM sms_report_settings WHERE id = ${SmsT[index].id}`;
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
    
    reloadTable();
    //const updated = [...SmsT];
     //updated.splice(index, 1);
     //setSmsT(updated);
   // setErrors(updatedErrors);

  };
/*
  const handleAdd = () => {
    //console.log(errors[0].parameter);
    const variable = replaceVariables(options.variable);
    console.log("variable", variable);
    const insertSql = `INSERT INTO errors (param, error) VALUES ('${variable}', '${newErrorMessage}');`;
    console.log("insertSQL", insertSql);
    const insertQuery = createQuery('now-1h', 'now', datasourceUID, insertSql);
    console.log(insertSql);

    fetchData(grafanaApiBaseUrl, method, headers, insertQuery);
    
    
    console.log(variable);
    setErrors([...errors, { id: errors.length + 1, parameter: options.variable, message: newErrorMessage }]);
    setNewErrorMessage('');
  };
  */
  //funkce add -> jen nektere tabulky btw

  //styling tabulky :()
  const table_theme = createTheme({
    overrides: {
      MuiFormControl:{
      root:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
      },
      MuiInputBase:{
        input:{
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
        }
      },
      MuiTableCell: {
        body:{
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
        },
        head:{
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
        },
        root: {
           backgroundColor: theme.colors.background.primary,
           border: `1px solid ${theme.colors.background.primary}`,
           fontFamily: theme.typography.fontFamily,
           fontSize: theme.typography.fontSize,
           color: theme.colors.text.maxContrast,
           padding: '5px 5px', // Adjust the padding as needed
         }
       },
     },
  });

   //mapování do listu optionu a.k.a nastav jméno a values to select listu (takový ten dropdown dolů)
   const options_: Option[] = devices.map((e)=>({
    value: e.id.toString(),
    label:`${e.name} @${e.id}`

   }));

   const handleActiveChange = (index: number,id: number)=>{
    const _smsT = [...SmsT];
    _smsT[index].settings.active=!_smsT[index].settings.active;
    setSmsT(_smsT);
    
//update active
const updateSql = `UPDATE sms_report_settings SET settings = '${JSON.stringify(_smsT[index].settings)}' WHERE id = ${_smsT[index].id}`;
const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
  console.log(updateSql);
  fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
};

interface FlexContainerStyle {
  display: string;
  float: 'left' | 'right' | 'none'; // Specify the valid values for float
}

const flexContainerStyle: FlexContainerStyle = {
  display: 'flex',
  float: 'left' // Or 'right', or 'none' based on your requirement
};
  return (
   
  

<ThemeProvider theme={table_theme}>
<CustomScrollbar>
<DropdownCheckboxForm
options = {options_}
selectedOptions={selectedOptions}
setSelectedOptions={setSelectedOptions}
name={name}
setName={setName}
phone={phone}
setPhone={setPhone}
onSubmit={handleFormSubmit}
styles={styles}

/> 

<TableContainer 
      component={Paper}
      style={{
          maxHeight: height, // Přidána dynamická výška podle parametru height
          overflowY: 'auto'  // Přidáno pro rolování obsahu
        }}
        elevation={0} // Nastaví elevation na 0, čímž odstraní stín
      >

    <Table >
      <TableHead >
        <TableRow >
          <TableCell>Název skupiny</TableCell>
          <TableCell>List zařízení</TableCell>
          <TableCell>Telefon</TableCell>
          <TableCell>Povoleno</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      {SmsT.map((row, index) => {
    const deviceElements = [];

    for (let deviceIndex = 0; deviceIndex < row.settings.deviceList.length; deviceIndex++) {
      const device = row.settings.deviceList[deviceIndex];
      const deviceName = row.settings.deviceNames[deviceIndex];
      const combinedDevice = `${deviceName} @${device}`; // Combine device and deviceName

      deviceElements.push(
        <Tooltip content={combinedDevice} key={deviceIndex}>
          <div style={flexContainerStyle}>{device}&nbsp; </div>
        </Tooltip>
      );
    }

    return (
      <TableRow key={index}>
        <TableCell>
          <Input
            disabled={true}
            value={row.group_name}
          />
        </TableCell>
        <TableCell>
          {deviceElements}

       

        </TableCell>
        {/* Rest of your table cells */}
    
        <TableCell>
              <Input
                value={row.settings.phone}
               disabled={true}
              />

              
            </TableCell>
    
                 <TableCell>
                 <Button onClick={() => handleActiveChange(index,row.id)}  variant={row.settings.active ? 'primary' : 'destructive'}>{row.settings.active?"Ano":"Ne"}</Button>
            </TableCell>
            <TableCell>
            <Button size="md" variant='destructive' onClick={()=>setSmsT(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: true } : device))} >Smazat</Button>
            <ConfirmModal isOpen={row.showModal}
            title={"Smazat sms upozornění"}
             body={"Opravdu chcete smazat sms upozornění?"}
              description={"Sms upozornění bude smazáno."} 
              confirmText={"Smazat"} confirmButtonVariant={"primary"}
               dismissText={"Zrušit"} icon={"exclamation-triangle"}
                onConfirm={()=>handleDelete(index)} onDismiss={()=>setSmsT(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: false } : device))} />

            
            
    
            
            
            </TableCell>
          </TableRow>
      
    );
  })}

        {
        /*
        {SmsT.map((row, index) => 
        (
          <TableRow key={index}>
            <TableCell  >

              <TextField
             
               disabled={true}
                value={row.group_name}
                
              />
            </TableCell>
            <TableCell>
              
            {row.settings.deviceList.map((device, deviceIndex) => (
              <Tooltip content={device} >
        <div key={deviceIndex}>{device}</div>
        </Tooltip>
      ))}

              <TextField
                value={row.settings.deviceList}
                onChange={(e) => handleChange(e, index, 'coef')}
                error={Boolean(formError[index].show)}
                  helperText={formError[index].message}
               
              />
            </TableCell>

            <TableCell>
              <TextField
                value={row.settings.phone}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />

              
            </TableCell>
    
                 <TableCell>
                 <Button onClick={() => handleActiveChange(index,row.id)}  variant={row.settings.active ? 'primary' : 'destructive'}>{row.settings.active?"Ano":"Ne"}</Button>
            </TableCell>
            <TableCell>
            <Button size="md" variant='destructive' onClick={() => setModal(true)} >Smazat</Button>
            <ConfirmModal isOpen={modal} title={"Smazat zařízení z databáze"} body={"Opravdu chcete smazat sms report z databáze?"} description={"Zařízení bude smazáno z databáze."} confirmText={"Smazat"} confirmButtonVariant={"primary"} dismissText={"Zrušit"} icon={"exclamation-triangle"} onConfirm={()=>handleDelete(index)} onDismiss={()=>setModal(!modal)} />
            </TableCell>
          </TableRow>
          
        ))}
            */}
      </TableBody>
     
  
    </Table>
   
  </TableContainer>

  </CustomScrollbar>
  </ThemeProvider>




  );

};
